/*

    1. BASE
    reset
    base

    2. Element
    Header
    Footer
    Graphics
    Row
    Button
    Banners
    Cards
    HeroIntro
    Icon
    Form
    Alerts
    Tooltip
    Loader
    Modal
    Stepper
    CausalCard
    DropZone
    Violentometro
    StatusBar
    Chatbox
    Hospital
    Tags
    Rating
    Accordion
    ProcessCard
    UserCard
    Quality
    DonationsTabs

    3. Layout
    Auth
    Donate
    Profile
    Dashboard
    UserDetail

    4. responsive
    Large
    Medium
    Small

*/
/* ------------------------------------------------------------
     VARIABLES
------------------------------------------------------------ */
@font-face {
  font-family: "CircularStd-Black";
  src: url("../public/assets/fonts/CircularStd-Black.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Bold";
  src: url("../public/assets/fonts/CircularStd-Bold.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Medium";
  src: url("../public/assets/fonts/CircularStd-Medium.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Book";
  src: url("../public/assets/fonts/CircularStd-Book.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "CircularStd-Light";
  src: url("../public/assets/fonts/CircularStd-Light.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
/* ------------------------------------------------------------
     MIXINS
------------------------------------------------------------ */
/* ------------------------------------------------------------
     RESET
------------------------------------------------------------ */
html,
body {
  -webkit-text-size-adjust: none;
  /* For iphone Landscape */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  /* For font looks antialiased */
  -moz-osx-font-smoothing: grayscale;
  /* Moz antialiased */
  text-rendering: optimizeLegibility;
  /* optimezy fonts */
  font-size: 16px;
  color: #1f1f1f;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 25px;
  height: 100%;
}

/* headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
blockquote,
h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.5px;
  font-weight: bold;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1,
.h1 {
  font-size: 35px;
  line-height: 40px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
}

h3,
.h3 {
  font-size: 28px;
  line-height: 35px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 25px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 25px;
}

/* outline */
* {
  -ms-touch-action: manipulation;
  -webkit-touch-action: manipulation;
  touch-action: manipulation;
}
*:focus {
  outline: none !important;
}

/* Placeholder */
::-webkit-input-placeholder {
  color: #CDCDCD !important;
}

::-moz-placeholder {
  color: #CDCDCD !important;
} /* firefox 19+ */
:-ms-input-placeholder {
  color: #CDCDCD !important;
} /* ie */
:-moz-placeholder {
  color: #CDCDCD !important;
}

/* Selection */
::-moz-selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}
::selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}

::-moz-selection {
  color: #1F7164;
  background: #E0FBE1;
  opacity: 1 !important;
}

/* mx auto */
.mx-auto {
  margin: 0 auto;
}

.col-reset {
  padding: 0;
}

/* Hidden */
.hidden {
  display: none !important;
}

/* Address */
address {
  font-style: initial;
}

/* padding */
.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-20 {
  padding-left: 20px !important;
}

/* Margin */
.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

/* text color */
.text-base,
.text-base * {
  color: #1f1f1f;
}

.text-danger,
.text-danger * {
  color: #AC0B1F;
}

.text-gray600,
.text-gray600 * {
  color: #8A8A8A;
}

.text-gray700,
.text-gray700 * {
  color: #707070;
}

.text-gray800,
.text-gray800 * {
  color: #545454;
}

.text-wine400,
.text-wine400 * {
  color: #8D3D64;
}

.text-pink400,
.text-pink400 * {
  color: #C47A90;
}

.text-purple400,
.text-purple400 * {
  color: #845DB6 !important;
}

.text-purple500,
.text-purple500 * {
  color: #845DB6 !important;
}

.text-green300,
.text-green300 * {
  color: #6BBBAE;
}

.text-green350,
.text-green350 * {
  color: #529770;
}

.text-green400,
.text-green400 * {
  color: #1F7164;
}

.text-green700,
.text-green700 * {
  color: #16615C;
}

.text-gray600,
.text-gray600 * {
  color: #8A8A8A;
}

.text-green900,
.text-green900 * {
  color: #144B59;
}

.text-wine400,
.text-wine400 * {
  color: #8D3D64;
}

/* text size */
.text-64,
.text-64 * {
  font-size: 64px;
  line-height: 70px;
}

.text-52,
.text-52 * {
  font-size: 52px;
  line-height: 60px;
}

.text-40,
.text-40 * {
  font-size: 40px;
  line-height: 45px;
}

.text-32,
.text-32 * {
  font-size: 32px;
  line-height: 40px;
}

.text-24,
.text-24 * {
  font-size: 24px;
  line-height: 30px;
}

.text-28,
.text-28 * {
  font-size: 28px;
  line-height: 30px;
}

.text-20,
.text-20 * {
  font-size: 20px;
  line-height: 28px;
}

.text-16,
.text-16 * {
  font-size: 16px;
  line-height: 25px;
}

.text-14,
.text-14 * {
  font-size: 14px !important;
  line-height: 25px !important;
}

.text-12,
.text-12 * {
  font-size: 12px !important;
  line-height: 25px !important;
}

/* icon */
.text-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.text-icon i {
  margin-right: 8px;
}

.text-hide {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  top: -2px;
  margin-left: 4px;
}
.text-hide * {
  margin-right: 4px;
  font-size: 8px;
  line-height: 20px;
}

/* text font */
.text-regular,
.text-regular * {
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-titular,
.text-titular * {
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-400,
.text-400 * {
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-500,
.text-500 * {
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-700,
.text-700 * {
  font-family: "CircularStd-Black", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.text-underline,
.text-underline * {
  text-decoration: underline;
}

strong {
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
}

/* input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

/* check list */
.check-list {
  list-style: none;
  padding-left: 0;
}
.check-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 18px;
}
.check-list li span {
  margin-right: 16px;
  color: #1F7164;
}

/* ------------------------------------------------------------
     BASE
------------------------------------------------------------ */
#root,
.app-main {
  width: 100%;
  height: 100%;
}

/* module */
.module {
  padding: 80px 0;
}

.module-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}

.module-top {
  padding-top: 80px;
  padding-bottom: 0;
}

.module40 {
  padding: 40px 0;
}

.module-bottom40 {
  padding-top: 0;
  padding-bottom: 40px;
}

.module-top40 {
  padding-top: 40px;
  padding-bottom: 0;
}

/* center list */
.center-list {
  list-style: none;
  padding: 24px 0;
  margin: 0;
}
.center-list li {
  margin-bottom: 32px;
}
.center-list li:last-child {
  margin-bottom: 0;
}

/* ------------------------------------------------------------
     HEADER
------------------------------------------------------------ */
header {
  padding: 25px 0;
  width: 100%;
  background: #fff;
}

.header__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo {
  width: 100px;
  margin: 0;
  position: relative;
  z-index: 11;
}
.header__logo figure {
  margin: 0;
}
.header__logo img {
  width: 100%;
}

/* main nav */
.main-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav ul li {
  margin-left: 64px;
}
.main-nav ul li a {
  color: #1f1f1f;
  position: relative;
  text-decoration: none;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-nav ul li a:not(.btn) {
  font-weight: 400;
}
.main-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: transparent;
}
.main-nav ul li a.active {
  color: #1F7164;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.main-nav ul li a.active:after {
  background: #1F7164;
}
.main-nav ul li a:hover {
  color: #1F7164;
}

/* open nav */
.open-nav {
  width: 50px;
  height: 25px;
  position: relative;
  -webkit-transform: scale(0.7) rotate(0deg);
          transform: scale(0.7) rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: none;
  z-index: 11;
}
.open-nav.active span {
  background: #fff;
}
.open-nav.active span:nth-child(1), .open-nav.active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.open-nav.active span:nth-child(2), .open-nav.active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.open-nav.active span:nth-child(1) {
  left: 5px;
  top: 7px;
}
.open-nav.active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}
.open-nav.active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.open-nav.active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.open-nav.active span:nth-child(5) {
  left: 5px;
  top: 22px;
}
.open-nav.active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 22px;
}
.open-nav span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #1F7164;
  opacity: 1;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.open-nav span:nth-child(even) {
  left: 50%;
}
.open-nav span:nth-child(odd) {
  left: 0px;
}
.open-nav span:nth-child(1), .open-nav span:nth-child(2) {
  top: 0px;
}
.open-nav span:nth-child(3), .open-nav span:nth-child(4) {
  top: 11px;
}
.open-nav span:nth-child(5), .open-nav span:nth-child(6) {
  top: 22px;
}

/* user control */
.user-control {
  position: relative;
}
.user-control a {
  text-decoration: none;
}

.user-options {
  position: absolute;
  right: 0;
  top: 50px;
  width: 360px;
  background: #F0FFF8;
  padding: 20px;
  border: 2px solid #1F7164;
  border-radius: 12px 0 12px 12px;
  display: none;
  z-index: 10;
}

.user-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #B1B1B1;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.user-options__top article {
  padding-left: 24px;
}
.user-options__top article h3 {
  margin-bottom: 0;
}

.user-options__bottom ul {
  list-style: none;
  padding: 0 22px;
  margin: 0;
}
.user-options__bottom ul li {
  width: 100%;
  margin-bottom: 29px;
}
.user-options__bottom ul li:last-child {
  margin-bottom: 0;
}
.user-options__bottom ul li i {
  margin-right: 43px;
}
.user-options__bottom ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* notifications */
.header__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__options .header-alert {
  margin-right: 24px;
}

.notifications {
  margin-right: 16px;
}

/* mobile options */
.main-nav__mobile {
  display: none;
}

.header-options__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.header-options__top > * {
  margin: 0 16px;
}
.header-options__top a {
  width: auto !important;
}

.header__options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__options.header--admin .form--search {
  margin: 0 16px;
  width: 300px;
}
.header__options .header-alert {
  margin-right: 24px;
}

/* header search */
.header-search-module {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 840px;
  padding-right: 32px;
}
.header-search-module figure {
  margin-right: 16px;
}
.header-search-module .form--search {
  width: 100%;
  max-width: 791px;
}

/* header admin */
.header-admin {
  background: #F6F6F6;
}
.header-admin .open-nav,
.header-admin .main-nav {
  display: none;
}

.header-admin__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
}
.header-admin__top .icon-header {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1300px) {
  .main-nav ul li {
    margin-left: 34px;
  }
}
@media (max-width: 1200px) {
  .main-nav ul li {
    margin-left: 34px;
  }
  .header__options {
    position: relative;
  }
  .header__options .header-alert {
    position: absolute;
    top: 60px;
    right: 0;
  }
  .header__options .header-alert p {
    font-size: 14px;
  }
}
@media (min-width: 993px) {
  .user-control:hover .user-options {
    display: block;
  }
  .user-control-button {
    pointer-events: none;
  }
}
@media (max-width: 992px) {
  .header__options {
    display: none;
  }
  .open-nav {
    display: block;
  }
  .main-nav {
    opacity: 0;
    pointer-events: none;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .main-nav ul {
    background: #1F7164;
    height: auto;
    display: block;
    padding: 130px 15px 30px 15px;
  }
  .main-nav ul li {
    width: 100%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    padding: 0 15px;
  }
  .main-nav ul li:last-child {
    margin-bottom: 0;
  }
  .main-nav ul li a {
    color: #fff;
    width: 100%;
    display: block;
  }
  .main-nav ul li a.active {
    color: #E0FBE1;
  }
  .main-nav ul li a.active:after {
    background: #E0FBE1;
  }
  .main-nav ul li a:hover {
    color: #E0FBE1;
  }
  .main-nav.active {
    opacity: 1;
    pointer-events: initial;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .header-admin .open-nav,
  .header-admin .main-nav {
    display: block;
  }
  .header-admin__top .icon-header {
    margin-right: 16px;
  }
}
@media (max-width: 768px) {
  .main-nav ul li {
    max-width: 540px;
  }
  .header-admin__top {
    padding: 16px;
  }
}
@media (max-width: 680px) {
  .main-nav ul {
    padding-top: 120px;
  }
  .main-nav ul li {
    padding: 0 30px;
  }
}
/* ------------------------------------------------------------
     FOOTER
------------------------------------------------------------ */
footer {
  background: #6BBBAE;
  padding: 32px 0;
  width: 100%;
}
footer .container p,
footer .container a,
footer .container h1,
footer .container h2,
footer .container h3,
footer .container h4,
footer .container h5,
footer .container h6 {
  color: #1f1f1f;
}
footer .container a {
  font-weight: 400;
  text-decoration: none;
}

.footer__logo {
  width: 130px;
  display: block;
  margin-bottom: 16px;
}
.footer__logo figure {
  margin: 0;
  width: 100%;
}
.footer__logo figure img {
  width: 100%;
}

.footer__row {
  width: 100%;
  margin-bottom: 16px;
}

/* social */
.social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}
.social li {
  margin-right: 24px;
}

/* contact */
.contact {
  padding: 0;
  margin: 0;
  list-style: none;
}
.contact li {
  width: 100%;
  margin-bottom: 8px;
}
.contact li:last-child {
  margin-bottom: 0;
}
.contact li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact li a .icon {
  margin-right: 8px;
}

/* quick links */
.quicklinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.quicklinks li {
  width: 100%;
  margin-bottom: 16px;
}
.quicklinks li:last-child {
  margin-bottom: 0;
}
.quicklinks li a {
  font-weight: bold;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .footer__col {
    margin-bottom: 32px;
  }
  .footer__col .quicklinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__col .quicklinks li {
    width: 100%;
    margin-bottom: 24px;
  }
  .footer__col .quicklinks li:last-child {
    margin-bottom: 0;
  }
  .footer__quicklinks {
    display: none;
  }
}
/* ------------------------------------------------------------
     ROW
------------------------------------------------------------ */
.image-350 {
  margin: 0;
}
.image-350,
.image-350 img {
  max-width: 350px;
  width: 100%;
}

.image-270 {
  margin: 0;
}
.image-270,
.image-270 img {
  max-width: 270px;
  width: 100%;
}

.image-190 {
  margin: 0;
}
.image-190,
.image-190 img {
  max-width: 190px;
  width: 100%;
}

.image-64 {
  margin: 0;
}
.image-64,
.image-64 img {
  max-width: 64px;
  width: 100%;
}

.image-39 {
  margin: 0;
  width: 39px;
  height: 39px;
}
.image-39 img {
  width: 100%;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .image-270,
  .image-270 img {
    max-width: 190px;
  }
}
/* ------------------------------------------------------------
     GRAPHICS
------------------------------------------------------------ */
.row-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.row-middle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* ------------------------------------------------------------
     BUTTON
------------------------------------------------------------ */
a,
button {
  color: #1F7164;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border: 0;
  background: transparent;
  cursor: pointer;
}
a:hover,
button:hover {
  text-decoration: none;
}
a.btn--simple,
button.btn--simple {
  font-size: 20px;
  text-decoration: none;
}
a.btn--simple:hover,
button.btn--simple:hover {
  color: #16615C;
}
a.btn--danger,
button.btn--danger {
  color: #AC0B1F;
  font-size: 20px;
  text-decoration: none;
}
a.btn--back,
button.btn--back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
}
a.btn--back .icon,
button.btn--back .icon {
  margin-right: 8px;
}

.btn {
  min-height: 60px;
  border-radius: 12px;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
}
.btn.btn--type1 {
  color: #fff;
  background: #1F7164;
}
.btn.btn--type1:hover {
  background: #6BBBAE;
  color: #144B59;
}
.btn.btn--type2 {
  color: #1F7164;
  background: transparent;
  border: 2px solid #1F7164;
}
.btn.btn--type2:hover {
  background: #6BBBAE;
  color: #144B59;
  border-color: #6BBBAE;
}
.btn.btn--type3 {
  color: #fff;
  background: #845DB6;
}
.btn.btn--type3:hover {
  background: #e1d5ef;
  color: #845DB6;
}
.btn.btn--type4 {
  color: #144B59;
  background: #CDF8E5;
  border: 1px solid #529770;
}
.btn.btn--type4:hover {
  background: #A0DAB3;
}
.btn.btn--type4.active {
  background: #A0DAB3;
}
.btn.btn--type5 {
  background: #CDF8E5;
  color: #845DB6;
}
.btn.btn--type5:hover {
  background: #fff;
}
.btn.btn--type5 .icon {
  margin-right: 8px;
}
.btn.btn--100 {
  width: 100%;
}
.btn.btn--180 {
  width: 180px;
}
.btn.btn--290 {
  width: 290px;
}
.btn.btn--simple {
  font-size: 20px;
  color: #1F7164;
  text-decoration: underline;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn.btn--simple:hover {
  text-decoration: none;
}
.btn.btn--simple-no-underline {
  font-size: 20px;
  color: #1F7164;
  text-decoration: none;
  font-family: "CircularStd-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn.btn--simple-no-underline:hover {
  text-decoration: none;
}
.btn.btn--delete {
  color: #fff;
  background: #9D1D2A;
}
.btn.btn--delete:hover {
  background: #AC0B1F;
}
.btn.btn--code {
  border: 1px solid #E4A9BB;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 138px;
  width: 322px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn.btn--code:hover, .btn.btn--code.active {
  border-color: #845DB6;
  background: #E0FBE1;
}
.btn.btn--code:hover i,
.btn.btn--code:hover p, .btn.btn--code.active i,
.btn.btn--code.active p {
  color: #1F7164;
}
.btn.btn--code i {
  font-size: 30px;
}
.btn.btn--code p {
  width: 80%;
  text-align: center;
  margin: 0;
}
.btn.btn--close {
  background: #E0FBE1;
  padding: 16px;
}
.btn.btn--close span {
  font-size: 30px;
  color: #1F7164;
}
.btn.btn--chat {
  color: #fff;
  background: #1F7164;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 24px;
  height: 60px;
}
.btn.btn--chat:hover {
  background: #16615C;
}
.btn.btn--chat i {
  margin-left: 16px;
}
.btn.btn--icon .icon {
  font-size: 32px;
  margin-right: 8px;
}
.btn[disabled] {
  background: #CDCDCD;
  color: #8A8A8A;
  border-color: #CDCDCD;
}
.btn.btn--370 {
  width: 370px;
}
.btn.btn--270 {
  max-width: 270px;
}
.btn.btn--logout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #144B59;
  border: 0;
}
.btn.btn--logout:hover {
  color: #CDF8E5;
}
.btn.btn--logout .icon {
  margin-right: 8px;
}
.btn.btn--list {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 0;
}
.btn.btn--list:hover {
  background: #CDF8E5;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(171, 201, 172, 0.57), 0px 0px 5px 2px #91D6C4;
          box-shadow: 0px 0px 10px 0px rgba(171, 201, 172, 0.57), 0px 0px 5px 2px #91D6C4;
}
.btn.btn--list div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.btn.btn--list div i {
  margin-right: 8px;
}
.btn.btn--taller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}
.btn.btn--taller figure {
  margin-right: 24px;
}
.btn.btn--taller p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  padding: 0;
  margin: 0;
}
.btn.btn--taller p span {
  display: block;
  width: 100%;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn.btn--colectiva {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  padding: 8px 16px;
  background: #F6F6F6;
  font-size: 16px;
}
.btn.btn--colectiva p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 200px;
}
.btn.btn--colectiva p span {
  display: block;
  width: 100%;
}
.btn.btn--colectiva .data-table__number {
  font-weight: 400;
  color: #8A8A8A;
  font-size: 14px;
}
.btn.btn--with-number {
  background: #CDF8E5;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  height: 39px;
  min-height: initial;
}
.btn.btn--with-number i {
  margin-left: 20px;
}

.btn--list__label {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-size: 16px;
  text-align: left;
}

.btn--list__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
}

.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.button-container .btn {
  margin: 0 8px;
}

/* -----

MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     BANNERS
------------------------------------------------------------ */
.banner-color {
  background: #E0FBE1;
  padding: 32px;
}

/* -----

MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CARDS
------------------------------------------------------------ */
.card {
  border-color: #D295BF;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.card-title {
  margin-bottom: 24px;
}

/* item card */
.item-workshop {
  border: 2px solid #845DB6;
  border-radius: 10px;
}
.item-workshop article {
  padding: 16px 20px;
  background: #9B7AC4;
}
.item-workshop .btn {
  width: 100%;
}

.item-workshop__image {
  position: relative;
  margin: 0;
  height: 154px;
}
.item-workshop__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.item-workshop__image .badge {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

/* stock list */
.stock-list {
  list-style: none;
  padding: 8px 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #845DB6;
  margin: 0;
}
.stock-list li {
  margin: 0 2px;
}
.stock-list li .icon {
  color: #CDCDCD;
  font-size: 16px;
}
.stock-list li .icon.stock--enabled {
  color: #D295BF;
}

.item-workshop__col {
  margin-bottom: 16px;
}

.card-row-item {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #E4A9BB;
  height: 100%;
}

.card-row-item__image {
  margin: 0;
  height: 250px;
  padding: 24px 23px;
}
.card-row-item__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.card-row-item__title {
  padding: 32px 0;
  width: 100%;
}

.card-row-item__text {
  padding-bottom: 32px;
  width: 100%;
}
.card-row-item__text p:last-child {
  margin-bottom: 0;
}

/* info card */
.info-card {
  border: 1px solid #91D6C4;
  border-radius: 16px;
  height: 100%;
}
.info-card.type--1 .info-card__head {
  background: #CDF8E5;
}
.info-card.type--1 .info-card__head p {
  color: #529770;
}
.info-card.type--2 {
  border-color: #F9D3D3;
}
.info-card.type--2 .info-card__head {
  background: #F9D3D3;
}
.info-card.type--2 .info-card__head p {
  color: #8D3D64;
}

.info-card__head {
  padding: 16px;
  background: #91D6C4;
  border-radius: 16px 16px 0 0;
  min-height: 108px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.info-card__body {
  padding: 32px;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .card-row-item {
    padding: 18px;
  }
  .card-row-item__text {
    min-height: initial;
  }
  .card-row-item__title {
    padding: 16px 0;
  }
  .card-row-item__image {
    height: 200px;
  }
  .card {
    padding: 10px;
  }
}
/* ------------------------------------------------------------
     HERO-INTRO
------------------------------------------------------------ */
/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .hero-intro__col-top {
    margin-bottom: 32px;
  }
}
/* ------------------------------------------------------------
     ICON
------------------------------------------------------------ */
.icon {
  display: inline-block;
}

.icon--instagram {
  background: url(/public/assets/images/icons/instagram.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--twitter {
  background: url(/public/assets/images/icons/twitter.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--phone {
  background: url(/public/assets/images/icons/phone.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--phone-call {
  background: url(/public/assets/images/icons/phone-call.svg) center center no-repeat;
  background-size: contain;
  width: 34px;
  height: 34px;
}

.icon--email {
  background: url(/public/assets/images/icons/email.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--upload {
  background: url(/public/assets/images/icons/upload.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--upload-checked {
  background: url(/public/assets/images/icons/upload-checked.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--user-small {
  background: url(/public/assets/images/icons/user.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--search {
  background: url(/public/assets/images/icons/search.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--logout {
  background: url(/public/assets/images/icons/logout.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--log-close {
  background: url(/public/assets/images/icons/log-close.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--user-big {
  background: url(/public/assets/images/icons/user-big.svg) center center no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
}

.icon--chat {
  background: url(/public/assets/images/icons/chat.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--message {
  background: url(/public/assets/images/icons/message.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--money {
  background: url(/public/assets/images/icons/money.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--amex {
  background: url(/public/assets/images/icons/amex.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--mastercard {
  background: url(/public/assets/images/icons/mastercard.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--visa {
  background: url(/public/assets/images/icons/visa.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--paypal {
  background: url(/public/assets/images/icons/paypal.svg) center center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.icon--card {
  background: url(/public/assets/images/icons/card.svg) center center no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.icon--clip {
  background: url(/public/assets/images/icons/clipboard.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--notification-on {
  background: url(/public/assets/images/icons/notification-on.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--notification-on-white {
  background: url(/public/assets/images/icons/notification-on-white.svg) center center no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.icon--notification-off {
  background: url(/public/assets/images/icons/notification-off.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--admin-small {
  background: url(/public/assets/images/icons/user-pic-2.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--superadmin {
  background: url(/public/assets/images/icons/user-pic-3.svg) center center no-repeat;
  background-size: contain;
  width: 44px;
  height: 44px;
}

.icon--back {
  background: url(/public/assets/images/icons/arrow-left.svg) center center no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
}

.icon--message-clip {
  background: url(/public/assets/images/icons/message-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--user-clip {
  background: url(/public/assets/images/icons/user-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--calendar-clip {
  background: url(/public/assets/images/icons/calendar-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--edit-clip {
  background: url(/public/assets/images/icons/detail-clip.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--pin {
  background: url(/public/assets/images/icons/pin.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon--note {
  background: url(/public/assets/images/icons/note.svg) center center no-repeat;
  background-size: contain;
  width: 42px;
  height: 46px;
}

.icon--checked {
  background: url(/public/assets/images/icons/checked.svg) center center no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.icon--colectivas {
  background: url(/public/assets/images/icons/colectivas.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--derivar {
  background: url(/public/assets/images/icons/derivar.svg) center center no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.icon--derivar-small {
  background: url(/public/assets/images/icons/derivar.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--document {
  background: url(/public/assets/images/icons/document.svg) center center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.icon--equipo {
  background: url(/public/assets/images/icons/equipo.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--mujeres {
  background: url(/public/assets/images/icons/mujeres.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--donativos {
  background: url(/public/assets/images/icons/donativos.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon--arrow-right {
  background: url(/public/assets/images/icons/arrow-right.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--coin {
  background: url(/public/assets/images/icons/coin.svg) center center no-repeat;
  background-size: contain;
  width: 27px;
  height: 27px;
}

.icon--search-bar {
  background: url(/public/assets/images/icons/search-bar.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--edit {
  background: url(/public/assets/images/icons/edit.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--arrow-right-large {
  background: url(/public/assets/images/icons/arrow-right-large.svg) center center no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
}

.icon--user-single {
  background: url(/public/assets/images/icons/user-single.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--change {
  background: url(/public/assets/images/icons/change.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--delete {
  background: url(/public/assets/images/icons/delete.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--remove {
  background: url(/public/assets/images/icons/remove.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--user-add {
  background: url(/public/assets/images/icons/user-add.svg) center center no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
}

.icon--warning {
  background: url(/public/assets/images/icons/warning.svg) center center no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
}

.icon--evaluaciones {
  background: url(/public/assets/images/icons/evaluaciones.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.icon--calendar-color {
  background: url(/public/assets/images/icons/calendar-color.svg) center center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.icon--phone-black {
  background: url(/public/assets/images/icons/phone-black.svg) center center no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}

/* icon text */
.icon-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.icon-text i {
  margin-right: 8px;
}

/* colors */
.icon--success {
  color: #46BC7C;
}

.icon-user-pic {
  width: 39px;
  height: 39px;
  margin: 0;
}
.icon-user-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .row-end {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* ------------------------------------------------------------
     FORM
------------------------------------------------------------ */
.form-block {
  width: 100%;
}
.form-block form {
  width: 100%;
}

.form-row {
  margin-bottom: 21px;
  width: 100%;
}
.form-row:last-child {
  margin-bottom: 0;
}
.form-row.form--last {
  text-align: center;
}
.form-row.form--last .btn {
  width: 100%;
}
.form-row.last--button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-row.last--button .btn {
  width: auto;
  margin: 0 8px;
}
.form-row.form--search {
  position: relative;
}
.form-row.form--search input {
  padding-left: 36px;
  border-width: 2px;
}
.form-row.form--search .icon {
  position: absolute;
  left: 10px;
  top: 18px;
}

small.required {
  color: #1F7164;
}

.form-label {
  font-size: 16px;
}
.form-label.label--icon {
  text-wrap: balance;
}
.form-label.label--icon .icon {
  color: #1F7164;
  font-size: 21px;
  line-height: 25px;
  position: relative;
  top: 4px;
  margin-left: 4px;
}
.form-label.error {
  color: #DB443A;
}

.form-control,
.form-select {
  border-color: #91D6C4;
  border-radius: 12px;
  height: 56px;
}
.form-control:focus,
.form-select:focus {
  border-color: #91D6C4;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.form-control.form--green,
.form-select.form--green {
  border-color: #91D6C4;
}
.form-control.form--green:focus,
.form-select.form--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.form-control.form--gray,
.form-select.form--gray {
  border: 0;
  background: #F6F6F6;
}
.form-control.error,
.form-select.error {
  border-color: #DB443A;
}
.form-control.form--filled,
.form-select.form--filled {
  background: #CDF8E5;
}

textarea.form-control {
  height: 100px;
}

.form-password {
  position: relative;
}
.form-password .toggle-password {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* password rules */
.password-rules {
  padding-top: 24px;
  color: #8A8A8A;
}
.password-rules ul li {
  color: #8A8A8A;
}

.password-strength {
  width: 100%;
  background: #CDCDCD;
  height: 15px;
  border-radius: 8px;
  position: relative;
}

.password-strength__bar {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
}

.form-check.check--small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.check--small .form-check-input {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  margin-top: 0;
}
.form-check.check--small label {
  font-weight: 500;
}
.form-check.accordeon--checker {
  position: relative;
  border-radius: 12px;
  padding: 10px 10px 12px 10px;
  margin-bottom: 24px;
}
.form-check.accordeon--checker:last-child {
  margin-bottom: 0;
}
.form-check.accordeon--checker input,
.form-check.accordeon--checker label {
  position: relative;
  z-index: 1;
}
.form-check.accordeon--checker input {
  margin-left: 0;
}
.form-check.accordeon--checker label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2px;
}
.form-check.form-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.form-switch input {
  width: 60px;
  height: 34px;
  margin-right: 16px;
  background-color: #B1B1B1;
  border: 0;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}
.form-check.form-switch input:after {
  display: none;
}
.form-check.form-switch input:checked {
  background-color: #1F7164;
}
.form-check.check--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check.check--flex input {
  margin-top: 0;
  margin-right: 16px;
}

.form-check-input[type=checkbox] {
  width: 28px;
  height: 28px;
  border: 1px solid #1F7164;
  margin-top: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.form-check-input[type=checkbox]:after {
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  opacity: 0;
  padding-top: 2px;
}
.form-check-input[type=checkbox]:checked {
  background-color: #fff;
  border-color: #6BBBAE;
  color: #1F7164;
}
.form-check-input[type=checkbox]:checked + .form-check-input__back {
  background: #CDF8E5;
}
.form-check-input[type=checkbox]:checked:after {
  opacity: 1;
}
.form-check-input[type=checkbox].checkbox--green {
  border-color: #91D6C4;
}
.form-check-input[type=checkbox].checkbox--green:checked {
  color: #2F6858;
}
.form-check-input[type=checkbox].checkbox--green:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}

.accordeon-number {
  padding-right: 16px;
}

.form-check-input__back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F0FFF8;
  border-radius: 12px;
}

.form-check-input {
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #1F7164;
  border-color: #1F7164;
}
.form-check-input:focus {
  border-color: #16615C;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}

/* phone */
.react-international-phone-input-container {
  width: 100%;
}
.react-international-phone-input-container input[type=tel] {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #1F7164;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 56px;
  margin-left: 8px;
}
.react-international-phone-input-container input[type=tel]:focus {
  border-color: #1F7164;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.react-international-phone-input-container button.react-international-phone-country-selector-button {
  border: var(--bs-border-width) solid #1F7164;
  border-radius: 12px;
  height: 56px;
}
.react-international-phone-input-container button.react-international-phone-country-selector-button .react-international-phone-country-selector-button__dropdown-arrow {
  background: url(/public/assets/images/icons/arrow-down.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: 0;
  margin-left: 8px;
}

/* verification code */
.vi__container {
  padding: 0;
  max-width: 400px;
  margin: 0;
}
.vi__container .vi__character {
  width: 100%;
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1F7164;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid #1F7164;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-radius: 12px;
  height: 64px;
  line-height: 56px;
  margin-left: 0;
  font-size: 28px;
}
.vi__container .vi__character.character--selected {
  border-color: #1F7164;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
  outline: initial;
}
.vi__container .vi__character:not(.character--inactive) {
  border-color: #529770;
}

/* form multi */
.form-check-multi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-check-multi .form-check {
  margin-right: 32px;
}
.form-check-multi .form-check:last-child {
  margin-right: 0;
}

/* row check 2 */
.row-check-2.row--check-top .form-check {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.row-check-2 .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.row-check-2 .form-check label {
  padding-left: 16px;
}

.row-check-2__col {
  margin-bottom: 24px;
  padding-right: 0;
}

/* Tag search */
.ReactTags__tags .ReactTags__selected {
  margin-bottom: 8px;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag {
  border-radius: 90px;
  padding: 6px 10px;
  background: #CDF8E5;
  border: 1px solid #529770;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #529770;
  font-weight: 700;
  text-transform: uppercase;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag .ReactTags__remove {
  font-size: 18px;
  color: #529770;
}
.ReactTags__tags .ReactTags__selected .tag-wrapper.ReactTags__tag .ReactTags__remove svg {
  fill: #144B59;
}

.ReactTags__tagInput {
  margin-bottom: 32px;
}
.ReactTags__tagInput .ReactTags__tagInputField {
  border: 1px solid #91D6C4;
  border-radius: 12px;
  height: 56px;
  width: 100%;
  background-color: #fff;
  background-image: url(/public/assets/images/icons/search-big.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 16px 18px;
  padding-left: 48px;
}
.ReactTags__tagInput .ReactTags__tagInputField:focus {
  border-color: #91D6C4;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
          box-shadow: 0px 0px 10px 0px rgba(84, 188, 188, 0.53), 0px 0px 5px 2px rgba(84, 188, 188, 0.28);
}
.ReactTags__tagInput .ReactTags__suggestions {
  position: absolute;
  background: #F6F6F6;
  border-radius: 12px;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
}
.ReactTags__tagInput .ReactTags__suggestions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ReactTags__tagInput .ReactTags__suggestions ul li {
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 12px;
  cursor: pointer;
}
.ReactTags__tagInput .ReactTags__suggestions ul li span mark {
  background: #91D6C4;
  border-radius: 12px;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__activeSuggestion {
  background: #CDF8E5;
  color: #529770;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__suggestion {
  padding: 12px 10px;
  font-size: 13px;
  color: #1f1f1f;
}
.ReactTags__tagInput .ReactTags__suggestions .ReactTags__suggestion:hover {
  background: #91D6C4;
  color: #6BBBAE;
}

.form-check-label.label--icon .icon {
  margin-left: 8px;
  font-size: 21px;
  line-height: 25px;
  color: #1F7164;
  position: relative;
  top: 4px;
}

.form-input-hide {
  border: 1px solid #91D6C4;
  border-radius: 12px;
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 24px;
}
.form-input-hide p {
  margin: 0;
}
.form-input-hide input {
  width: 140px;
  font-size: 24px;
  text-align: center;
  border: 0;
}

/* form search */
.form-search-complete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  z-index: 19;
}
.form-search-complete .autocomplete-search {
  width: 70%;
  margin-bottom: 0;
  padding-right: 16px;
}
.form-search-complete .autocomplete-search.search--100 {
  width: 100%;
}
.form-search-complete .autocomplete-search .icon {
  top: 20px;
}
.form-search-complete .btn {
  width: 30%;
}

.autocomplete-search .autocomplete-search__input .wrapper {
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 12px;
  height: 56px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  border-color: #91D6C4;
}
.autocomplete-search .autocomplete-search__input .wrapper:focus, .autocomplete-search .autocomplete-search__input .wrapper:focus-within {
  border-color: #6BBBAE;
  -webkit-box-shadow: 0 0 0 0.25rem #cdf8e5;
          box-shadow: 0 0 0 0.25rem #cdf8e5;
}
.autocomplete-search .autocomplete-search__input .wrapper svg {
  margin-left: 0;
  width: 25px;
  height: 40px;
  fill: #8A8A8A;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer {
  border-radius: 18px;
  position: relative;
  top: 20px;
  background: #fff;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  padding: 0;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul {
  padding: 0;
  overflow: hidden;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul li[data-test=result].selected {
  background: #F4E0FB;
}
.autocomplete-search .autocomplete-search__input .wrapper .sc-gEvEer ul li[data-test=result] svg {
  display: none;
}
.autocomplete-search .autocomplete-search__input .wrapper .line {
  display: none;
}

.search-checks-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.search-checks-list li {
  width: 100%;
  margin-bottom: 16px;
}
.search-checks-list li:last-child {
  margin-bottom: 0;
}

/* double button */
.double-btn-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.double-btn-action .btn {
  width: 45%;
}

/* -----

MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .form-row__col-small {
    margin-bottom: 21px;
  }
  .form-big-row {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .form-big-row.big-row--last .btn {
    margin: 0 8px 24px 8px;
    width: 100%;
  }
  .form-row.last--button {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .form-row.last--button .btn {
    width: 100%;
    margin: 0 auto 16px auto;
  }
}
/* ------------------------------------------------------------
     ALERTS
------------------------------------------------------------ */
.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
}
.alert.alert--info {
  background: #F1F1F1;
  border-radius: 0;
}
.alert.alert-danger {
  background: transparent;
  border: 0;
  color: #DB443A;
  padding: 0;
}
.alert.alert-danger.alert--small {
  padding: 0;
  margin-top: 4px !important;
}
.alert.alert--complete {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.alert.alert--complete > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert.alert-success {
  background: #A9E5B7;
  border-color: #2F6858;
  color: #2F6858;
}
.alert.alert-success a {
  color: #2F6858;
  font-weight: 700;
}
.alert.alert--small {
  padding: 5px 10px;
  font-size: 14px;
}
.alert.alert--soft-success {
  background: #F0FFF8;
  border-color: #6BBBAE;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: #1F7164;
}
.alert.alert--soft-success .icon {
  position: relative;
  top: 4px;
}
.alert.alert--soft-danger {
  background: #FFDED7;
  border-color: #600c14;
  color: #AC0B1F;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.alert.alert--soft-danger .icon {
  position: relative;
  top: 4px;
}
.alert.alert--simple-check {
  color: #46BC7C;
  padding-left: 0;
  padding-right: 0;
}
.alert.alert--simple-check .icon {
  font-size: 40px;
  margin-right: 16px;
}
.alert.alert--icon {
  padding: 16px;
}
.alert article:last-child {
  padding-left: 16px;
}
.alert p {
  margin-bottom: 0;
}
.alert a {
  font-weight: 700;
}
.alert ul {
  margin-bottom: 0;
}

/* header alert */
.header-alert {
  border: 2px solid #AC0B1F;
  color: #AC0B1F;
  background: #fff;
  border-radius: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 16px;
  min-width: 300px;
}
.header-alert p {
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.header-alert .icon {
  margin-right: 16px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     TOOLTIP
------------------------------------------------------------ */
.tooltip-inner {
  background-color: #CDF8E5;
  border: 2px solid #6BBBAE;
  border-radius: 12px 12px 12px 0;
  color: #1f1f1f;
  padding: 20px;
  font-weight: 500;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  max-width: 300px;
  width: inherit;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #6BBBAE;
}

.tooltip-options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tooltip-options li {
  width: 100%;
  margin-bottom: 8px;
}
.tooltip-options li:last-child {
  margin-bottom: 0;
}
.tooltip-options li a,
.tooltip-options li button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  text-align: left;
}
.tooltip-options li a .icon,
.tooltip-options li button .icon {
  margin-right: 8px;
}

.tooltip--white.bs-tooltip-start .tooltip-arrow::before, .tooltip--white.bs-tooltip-bottom .tooltip-arrow::before {
  border-left-color: #fff;
  border-bottom-color: #fff;
}
.tooltip--white .tooltip-inner {
  background: #fff;
  border: 0;
  padding: 16px 30px;
  border-radius: 12px 0 12px 12px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -2px 7px 18px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -2px 7px 18px 0px rgba(0, 0, 0, 0.25);
}
.tooltip--white .tooltip-inner .tooltip-options li {
  margin-bottom: 16px;
}
.tooltip--white .tooltip-inner .tooltip-options li:last-child {
  margin-bottom: 0;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     LOADER
------------------------------------------------------------ */
.loader-wrapp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #CDF8E5;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #1F7164;
  border-bottom-color: #6BBBAE;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     MODAL
------------------------------------------------------------ */
.modal.modal--gray .modal-content {
  background: #F6F6F6;
}

.modal-header {
  border: 0;
}
.modal-header p {
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
}

.modal-content {
  border-radius: 16px;
  padding: 20px;
}

.modal-footer {
  border: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .modal-card__col {
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .modal-content {
    padding: 0;
  }
  .modal-selection__left {
    margin-bottom: 24px;
  }
}
/* ------------------------------------------------------------
     STEPPER
------------------------------------------------------------ */
.stepper {
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background: #CDCDCD;
  position: relative;
}

.stepper__container {
  background: #6BBBAE;
  height: 16px;
  border-radius: 8px;
  position: relative;
}

.stepper__figure {
  position: absolute;
  right: 0;
  top: -3px;
  width: 23px;
  height: 23px;
  background: #fff;
  border-radius: 50%;
  border: 4px solid #1F7164;
}

.stepper__text {
  background: #E0FBE1;
  border-radius: 5px;
  position: absolute;
  top: -40px;
  margin: 0;
  font-size: 14px;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1F7164;
  width: 60px;
  text-align: center;
  border: 1px solid #1F7164;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.stepper__text:after {
  content: "\f0dd";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  color: #1F7164;
  position: absolute;
  bottom: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CAUSAL CARD
------------------------------------------------------------ */
.causal-card {
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  border: 2px solid #C47A90;
}
.causal-card.card--color1 {
  border-color: #6BBBAE;
}
.causal-card.card--color1 h3 {
  color: #144B59;
}
.causal-card.card--color2 {
  border-color: #9B7AC4;
}
.causal-card.card--color2 h3 {
  color: #9B7AC4;
}
.causal-card h3 {
  color: #C47A90;
}

.card-accordeon {
  border: 0;
}
.card-accordeon .accordion-item {
  border: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button {
  font-size: 24px;
  line-height: 35px;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  background: transparent;
  border: 0;
  color: #144B59;
  -webkit-box-shadow: initial;
          box-shadow: initial;
  padding-left: 0;
  padding-right: 0;
}
.card-accordeon .accordion-item .accordion-header .accordion-button:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  border: 0;
  height: auto;
  width: auto;
  background: transparent;
}
.card-accordeon .accordion-item .accordion-header .accordion-button[aria-expanded=true]:after {
  color: #1F7164;
}
.card-accordeon .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordeon-text {
  margin-bottom: 5px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     DROPZONE
------------------------------------------------------------ */
.dropzone {
  padding: 16px;
  border: 1px dashed #1F7164;
  border-radius: 8px;
  text-align: center;
}

.dropzone__files {
  margin-top: 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.dropzone__files ul {
  list-style: none;
  padding: 16px 0 0 0;
  margin: 0;
  width: 100%;
}
.dropzone__files ul li {
  margin-bottom: 0;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     VIOLENTOMETRO
------------------------------------------------------------ */
.violent-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 19px;
  height: 52px;
}
.violent-row:last-child {
  margin-bottom: 0;
}
.violent-row .form-check-input[type=checkbox] {
  border-color: #91D6C4;
}
.violent-row .form-check-input[type=checkbox]:checked {
  color: #2F6858;
}
.violent-row .form-check-input[type=checkbox]:focus {
  border-color: #2F6858;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
          box-shadow: 0 0 0 0.25rem rgba(145, 214, 196, 0.44);
}
.violent-row .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 440px;
}
.violent-row .form-check.check--center label {
  padding-top: 6px;
}
.violent-row .form-check label {
  padding-left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 52px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-row .form-check label * {
  width: 100%;
}
.violent-row .form-check label small {
  display: block;
  font-size: 12px;
  letter-spacing: -0.3px;
}

.violent-number {
  color: #1f1f1f;
  margin-right: 40px;
  padding-right: 30px;
  text-align: right;
  position: relative;
  margin-bottom: 0;
  width: 50px;
  height: 24px;
  top: 5px;
}
.violent-number:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #1f1f1f;
  display: block;
  right: -14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.violent-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.violent-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 44px;
  margin-right: 16px;
  margin-left: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 0;
  position: relative;
}
.violent-figure:after {
  content: "";
  background: #EDEDED;
  width: 28px;
  border-radius: 30px;
  height: 100%;
  position: absolute;
  bottom: 12px;
  left: 50%;
  z-index: 1;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.violent-figure__end {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #7500BD;
  border: 10px solid #EDEDED;
  position: relative;
  top: -12px;
  z-index: 2;
}

.violent-level:first-child .violent-figure__item:first-child {
  border-radius: 30px 30px 0 0;
  height: 100px;
}
.violent-level.level1 p {
  top: 100%;
}

.violent-figure__item {
  width: 14px;
  height: 70px;
  position: relative;
  z-index: 3;
}
.violent-figure__item.item--color4 {
  background: #7500BD;
}
.violent-figure__item.item--color3 {
  background: #DC0303;
}
.violent-figure__item.item--color2 {
  background: #FFAB48;
}
.violent-figure__item.item--color1 {
  background: #FFE248;
}

.violent-level {
  width: 14px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.violent-level p {
  position: absolute;
  top: 50%;
  left: -30px;
  -webkit-transform: translateX(-50%) rotate(270deg);
          transform: translateX(-50%) rotate(270deg);
  margin: 0;
  white-space: nowrap;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .violent-number {
    display: none;
  }
  .violent-row .form-check-label {
    font-size: 14px;
    line-height: 14px;
  }
  .violent-row .form-check-label small {
    font-size: 11px;
    line-height: 18px;
  }
  .violent-row .form-check {
    width: 290px;
  }
}
/* ------------------------------------------------------------
     STATUS BAR
------------------------------------------------------------ */
.status-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 32px 0;
}

.status-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 16px;
}
.status-item.status--success .status-item__top hr {
  background-color: #529770;
}
.status-item.status--success .status-item__icon {
  background: #529770;
}
.status-item.status--process .status-item__icon {
  background: #C47A90;
  border: 5px solid #fff;
  outline: 2px solid #C47A90;
}

.status-item__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}
.status-item__top hr {
  width: 100%;
  height: 3px;
  background-color: #8A8A8A;
  border: 0;
  opacity: 1;
  margin: 0;
  width: 90%;
}

.status-item__icon {
  width: 23px;
  height: 22px;
  border-radius: 50%;
  background: #8A8A8A;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
}
.status-item__icon i {
  color: #fff;
}

.status-item__bottom p {
  margin: 0;
  max-width: 160px;
  font-size: 16px;
  line-height: 20px;
}

/* status bar mobile */
.status-bar__mobile {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.status-bar__circle {
  width: 70px;
  height: 70px;
  position: relative;
  margin-right: 24px;
}

.status-bar__step {
  background: #1F7164;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #fff;
  border-radius: 50%;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .status-bar {
    display: none;
  }
  .status-bar__mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px 0;
  }
}
@media (max-width: 680px) {
  .status-bar__step {
    font-size: 16px;
  }
}
/* ------------------------------------------------------------
     CHATBOX
------------------------------------------------------------ */
/* ------------------------------------------------------------
     CHATBOX
------------------------------------------------------------ */
.list-checker {
  list-style: none;
  padding: 0;
  margin: 0;
}
.list-checker li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}
.list-checker li:last-child {
  margin-bottom: 0;
}
.list-checker li article {
  padding-left: 16px;
}
.list-checker li article p {
  margin: 0;
}

.chatbox-info {
  background: #F6F6F6;
  padding: 40px 32px;
  border-radius: 8px;
  border: 1px solid #D295BF;
}

.chatbox-info__row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chatbox-action {
  background: #fff;
  padding: 32px;
  border-radius: 16px;
}

.chatbox-action__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 32px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.chatbox-action__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.chatbox-action__user:last-child {
  margin-bottom: 0;
}
.chatbox-action__user figure {
  margin: 0;
  width: 77px;
  height: 77px;
}
.chatbox-action__user figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.chatbox-action__user article {
  padding-left: 16px;
}
.chatbox-action__user article p,
.chatbox-action__user article h4,
.chatbox-action__user article h3,
.chatbox-action__user article h2 {
  margin: 0;
}

.chatbox__send {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.chatbox__send .rce-container-input {
  border-radius: 0 0 20px 20px;
}

/* placeholder */
.chatbox-placeholder {
  width: 100%;
  background: #FFEFFF url(/public/assets/images/background/pattern.png) center center repeat;
  background-size: 300px;
  height: 700px;
  border-radius: 10px;
  overflow: hidden;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .chatbox-action__user {
    margin-bottom: 16px;
  }
}
@media (max-width: 992px) {
  .chatbox-info__text {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .chatbox-info__module {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 24px;
  }
}
@media (max-width: 768px) {
  .chatbox-action__top {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .chatbox-info {
    padding: 40px 22px;
  }
}
/* ------------------------------------------------------------
     HOSPITAL
------------------------------------------------------------ */
.hospital-card__text .material-icons-outlined {
  font-size: 18px;
  margin-right: 8px;
}

.hospital-card {
  padding: 16px 22px;
  border: 1px solid #91D6C4;
  border-radius: 8px;
}
.hospital-card p {
  margin-bottom: 0;
}
.hospital-card p.tag {
  margin: 8px 0;
}

.hospital-card__end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/* map side */
.map-side {
  position: sticky;
  top: 0;
}
.map-side #map {
  height: 100% !important;
  min-height: 500px;
}

.hospital-wrapp__search {
  margin-bottom: 40px;
}

.map-side .btn--close {
  display: none;
}

.hospital-wrapp__show {
  display: none;
}

.map-small {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 32px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .hospital-wrapp__search {
    margin-bottom: 16px;
  }
  .map-side {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    padding: 30px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.4s all ease-in-out;
    transition: 0.4s all ease-in-out;
  }
  .map-side.active {
    opacity: 1;
    pointer-events: all;
  }
  .map-side .btn--close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    display: block;
  }
  .hospital-wrapp__show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .hospital-card__end {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin-top: 32px;
  }
  .hospital-wrapp > .row {
    margin: 0;
  }
  .hospital-wrapp > .row > div {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .hospital-card__end .btn {
    width: 100%;
  }
}
/* ------------------------------------------------------------
     TAGS
------------------------------------------------------------ */
.tag {
  text-transform: uppercase;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  border: 1px solid #8A8A8A;
  border-radius: 90px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 10px;
  background: #EDEDED;
}
.tag.tag--success {
  background: #A0DAB3;
  border-color: #144B59;
  color: #144B59;
}
.tag.tag--success .material-icons-outlined {
  color: #144B59;
}
.tag.tag--error {
  background: #E4A9BB;
  border-color: #8D3D64;
  color: #8D3D64;
}
.tag.tag--error .material-icons-outlined {
  color: #8D3D64;
}
.tag .material-icons-outlined {
  color: #707070;
  margin-right: 8px;
  font-size: 17px;
}
.tag.tag--warning {
  background: #F2E9C5;
  border-color: #8D633D;
  color: #8D633D;
}
.tag.tag--warning .material-icons-outlined {
  color: #8D633D;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     RATING
------------------------------------------------------------ */
/* rating */
.rating-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 240px;
  width: 100%;
}
.rating-form svg {
  height: 2em;
}

.rating-card {
  padding: 32px;
  border: 1px solid #E4A9BB;
  border-radius: 16px;
}

.rating-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rating-card__header h3 {
  margin-bottom: 0;
}
.rating-card__header figure {
  margin-right: 16px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     ACCORDION
------------------------------------------------------------ */
.accordion.accordion-regular .accordion-item .accordion-button {
  font-size: 21px;
}
.accordion .accordion-item {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
}
.accordion .accordion-item .accordion-item .accordion-button:after {
  background-image: url(../public/assets/images/icons/arrow-down-green.svg);
  background-size: 18px;
  background-size: 25px;
  width: 30px;
  height: 30px;
  background-position: center;
}
.accordion .accordion-item .accordion-button {
  font-size: 18px;
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0;
  color: #1f1f1f;
  border-radius: 0 !important;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: transparent;
}
.accordion .accordion-item .accordion-button:focus {
  border: 0;
  -webkit-box-shadow: initial;
          box-shadow: initial;
}
.accordion .accordion-item .accordion-button:after {
  background-image: url(../public/assets/images/icons/arrow-down.svg);
  background-size: 18px;
}
.accordion .accordion-item .accordion-collapse.show {
  border-top: 1px solid var(--bs-accordion-border-color);
}

.accordion-inner .accordion-item {
  border: 0;
}
.accordion-inner .accordion-item .accordion-header .accordion-button {
  background: #F6F6F6;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: initial;
          box-shadow: initial;
  color: #1f1f1f;
}
.accordion-inner .accordion-item .accordion-collapse.show {
  border: 0;
}

/* user pic selection */
.user-pic-selection {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.user-pic-selection li {
  margin-right: 8px;
  margin-bottom: 8px;
}
.user-pic-selection li button {
  background: transparent;
  border: 0;
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.user-pic-selection li button:hover {
  opacity: 0.5;
}
.user-pic-selection li button figure {
  width: 60px;
  height: 60px;
}
.user-pic-selection li button figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.accordion-table {
  width: 100%;
}
.accordion-table tr {
  background: #F6F6F6;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 6px;
}
.accordion-table tr td {
  padding: 8px 16px;
}

.accordion-reset-padding.accordion-header button {
  padding-left: 0 !important;
  padding-right: 0;
}

.accordion-wrapp {
  border-top: 1px solid #F9D3D3;
  margin-bottom: 16px;
}
.accordion-wrapp .accordion-item {
  border-bottom: 0;
}
.accordion-wrapp .accordion-item .accordion-collapse.show {
  border-top: 0;
}
.accordion-wrapp .accordion-header {
  padding-bottom: 0;
}
.accordion-wrapp .accordion-header button {
  border-bottom: 0 !important;
  -webkit-box-shadow: initial !important;
          box-shadow: initial !important;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .accordion-item-inner .text-20 {
    font-size: 16px !important;
    padding: 0;
  }
  .accordion-table tr {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .accordion-table tr td {
    width: 100%;
  }
  .accordion-table tr td:empty {
    display: none;
  }
  .accordion-table tr .text-right {
    text-align: left;
  }
}
/* ------------------------------------------------------------
     DATATABLE
------------------------------------------------------------ */
.data-table.table--result [role=cell]:first-child {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
  padding-left: 32px !important;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background: #CDF8E5;
  border-radius: 8px 8px 0 0;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  color: #1f1f1f;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
}
.data-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol > div {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #1F7164;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background: #E0FBE1;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px 0;
}
.data-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell a {
  text-decoration: none;
}
.data-table .rdt_Pagination {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.data-table .rdt_Pagination button:hover {
  background: #CDF8E5;
}
.data-table .form-check {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.data-table .form-check input {
  margin-right: 8px;
  margin-bottom: 5px;
  margin-left: 0;
}
.data-table .form-select {
  width: 70px;
  display: inline-block;
}

.data-table__time > span {
  display: block;
  width: 100%;
  text-align: center;
}

.data-table__number {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #8A8A8A;
  border-radius: 2px;
  padding: 10px;
  min-width: 45px;
  width: auto;
}
.data-table__number.number--wait {
  background: #CDCDCD;
  text-transform: uppercase;
}
.data-table__number.number--fail {
  background: #FFBDA8;
  color: #9D1D2A;
  border-color: #9D1D2A;
  text-transform: uppercase;
}
.data-table__number.disabled {
  opacity: 0.7;
}

.data-table__input {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.data-table__input p {
  margin: 0 4px;
}
.data-table__input input {
  width: 50px;
}

/* mobile */
.data-table-mobile__header {
  width: 100%;
  background: #1F7164;
  color: #fff;
  padding: 12px 30px;
  text-align: center;
}
.data-table-mobile__header p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.data-table-mobile__accordion table {
  width: 100%;
  font-size: 14px;
}
.data-table-mobile__accordion table td {
  padding: 24px 10px;
}
.data-table-mobile__accordion .accordion-item .accordion-header button {
  text-transform: uppercase;
  color: #1F7164;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  display: block;
  text-align: center;
}
.data-table-mobile__accordion .accordion-item .accordion-header button:after {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.data-table-mobile__accordion .accordion-item .accordion-header button[aria-expanded=true]:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.user-table-detail-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.user-table-detail-image figure {
  margin-right: 32px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     PAGINATOR
------------------------------------------------------------ */
.data-paginator {
  width: 100%;
  padding-top: 36px;
}
.data-paginator div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.data-paginator .btn {
  font-size: 12px;
  font-weight: 700;
  color: #545454;
  padding: 12px;
  width: 24px;
  height: 24px;
  min-height: initial;
  border-radius: 2px;
  margin: 0 6px;
}
.data-paginator .btn:hover {
  opacity: 0.7;
}
.data-paginator .btn.active {
  border-color: #D295BF;
  color: #D295BF;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     BADGES
------------------------------------------------------------ */
.badge {
  border-radius: 8px;
  margin: 0;
}
.badge.badge-label {
  padding: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.badge.badge-label .icon {
  margin-right: 6px;
}
.badge.badge-label.badge--danger {
  border: 1px solid #AC0B1F;
}
.badge.badge--success {
  background: #E0FBE1;
  border: 1px solid #529770;
  color: #E0FBE1;
}
.badge.badge--success * {
  color: #529770;
}
.badge.badge--primary {
  background: #E0FBFB;
  border: 1px solid #1C358E;
  color: #1C358E;
}
.badge.badge--primary * {
  color: #1C358E;
}
.badge.badge--info {
  background: #F4E0FB;
  border: 1px solid #711C8E;
  color: #711C8E;
}
.badge.badge--info * {
  color: #711C8E;
}
.badge.badge--blue {
  background: #BFFFFF;
  border: 1px solid #0B72AC;
  color: #0B72AC;
}
.badge.badge--blue * {
  color: #0B72AC;
}
.badge.badge--empty {
  height: 72px;
  width: 100%;
}
.badge.badge--status {
  border-radius: 8px;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
}
.badge.badge--status-acompanada {
  background: #E9D8FE;
  color: #792F78;
}
.badge.badge--status-registrada {
  background: #FFAB48;
  color: #fff;
}
.badge.badge--status-success {
  color: #fff;
  background: #46BC7C;
  border: 1px solid #1F7164;
}
.badge.badge--status-taller {
  color: #792F78;
  background: #F9D3D3;
}
.badge.badge--status-done {
  color: #3366FF;
  background: #D8ECFE;
}
.badge.badge--status-undone {
  color: #792F78;
  background: #D295BF;
}
.badge.badge--status-info {
  color: #8A8A8A;
  background: #F6F6F6;
}
.badge.badge--status-seguimiento {
  color: #529770;
  background: transparent;
  border: 1px solid #529770;
}
.badge.badge--status-acompanada {
  color: #8D3D64;
  background: #FFAB48;
  border: 1px solid #8D3D64;
}
.badge.badge--status-si-constituyo {
  color: #D17100;
  background: #FFF068;
  border: 1px solid #D17100;
}
.badge.badge--status-no-constituyo {
  color: #54326F;
  background: #D295BF;
  border: 1px solid #54326F;
}
.badge.badge--ola {
  color: #546D69;
  background: #A6ECD3;
}
.badge.badge--medica {
  color: #161639;
  background: #A6D7EC;
}
.badge.badge--colectiva {
  color: #A24B8F;
  background: #FEE2F8;
}
.badge.badge--icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.badge.badge--icon span {
  margin-right: 8px;
  font-size: 18px;
}
.badge.badge--warning {
  color: #8f7c04;
  background: #f1eaba;
}
.badge.badge--derivar {
  color: #E54500;
  background: #FFF1BF;
  border: 1px solid #E54500;
}
.badge.badge--lasamigas {
  color: #792F78;
  background: #E9D8FE;
  border: 1px solid #792F78;
}
.badge.badge--danger {
  color: #AC0B1F;
  background: #FFDED7;
}
.badge.badge--psicologica {
  color: #0B72AC;
  background: #DEFDFD;
}
.badge.badge--complejidad {
  color: #6A588F;
  background: #E0DFFB;
}
.badge.badge--discapacidad {
  color: #A24B8F;
  background: #F8DEF7;
}
.badge.badge--no-spanish {
  color: #16615C;
  background: #CDF8E5;
}
.badge.badge--active {
  color: #16615C;
  background: #E0FBE1;
  border: 1px solid #16615C;
}
.badge.badge--inactive {
  color: #AC0B1F;
  background: #FFDED7;
  border: 1px solid #AC0B1F;
}
.badge.badge--success-alert {
  background: #fff;
  border: 2px solid #6BBBAE;
  color: #6BBBAE;
  border-radius: 20px;
  display: inline-block;
  white-space: normal;
}

.badge-neutral {
  background: #F6F6F6;
  padding: 8px 16px;
  border-radius: 8px;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     NOTES
------------------------------------------------------------ */
.note-wrapp {
  width: 100%;
}

.note-row {
  width: 100%;
  margin-bottom: 28px;
}

.note-row__body {
  background: #FCEEF2;
  border: 1px solid #D295BF;
  border-radius: 8px;
  padding: 12px;
}
.note-row__body p {
  margin: 0;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     PROCESS CARD
------------------------------------------------------------ */
.card-process-info {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0;
}

.card-process__row {
  padding: 10px;
  width: 100%;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card-process__row p {
  margin-bottom: 0;
}
.card-process__row > div {
  width: 50%;
}

.card-process__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: right;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .card-process__row > div {
    width: 100%;
  }
  .card-process__right {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: left;
  }
  .card-process__right .btn {
    margin-top: 16px;
    width: 100%;
  }
  .card-process-info {
    padding: 16px 0;
  }
}
/* ------------------------------------------------------------
     USER CARD
------------------------------------------------------------ */
.user-card {
  background: #fff;
  border: 1px solid #6BBBAE;
  border-radius: 16px;
  padding: 32px;
  position: relative;
}
.user-card.user--hover {
  -webkit-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}
.user-card.user--hover:hover {
  background: #F0FFF8;
}
.user-card .chatbox-action__user {
  margin-bottom: 24px;
}
.user-card .chatbox-action__user:last-child {
  margin-bottom: 0;
}

.user-card__detail {
  background: #F0FFF8;
  padding: 16px;
}

.user-card__url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     QUALITY
------------------------------------------------------------ */
.quality-score__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.quality-score__number i {
  margin-right: 8px;
  font-size: 18px;
}

.quality-score-star ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quality-score-star ul li {
  margin-right: 8px;
}
.quality-score-star ul li:last-child {
  margin-right: 0;
}
.quality-score-star ul li i {
  color: #845DB6;
  font-size: 18px;
}

.quality-score-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quality-score-list > div {
  margin-right: 22px;
}
.quality-score-list > div:last-child {
  margin-right: 0;
}

.quality-score-info {
  width: 100%;
  margin-bottom: 20px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .quality-score-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .quality-score-list > div {
    width: 100%;
    margin-bottom: 16px;
  }
  .quality-score-list > div:last-child {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------
     DONATIONS TABS
------------------------------------------------------------ */
.data-info-tab {
  width: 100%;
}
.data-info-tab ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.data-info-tab ul li button {
  padding: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #845DB6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-weight: 400;
}
.data-info-tab ul li button:after {
  content: "";
  width: 100%;
  height: 4px;
  background: transparent;
  border-radius: 10px;
  display: block;
}
.data-info-tab ul li button.active {
  color: #54326F;
  font-weight: 500;
}
.data-info-tab ul li button.active:after {
  background: #54326F;
}
.data-info-tab ul li button i {
  margin-left: 8px;
}

.data-info__date-item {
  margin-bottom: 24px;
  width: 100%;
}
.data-info__date-item:last-child {
  margin-bottom: 0;
}

.data-donate-wrapp {
  width: 100%;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     CALENDAR
------------------------------------------------------------ */
.react-calendar {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
  background: #fff;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
.react-calendar .react-calendar__navigation {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 16px;
}
.react-calendar .react-calendar__navigation .react-calendar__navigation__label .react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  font-weight: 400;
  color: #1f1f1f;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
  text-align: center;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile {
  color: #1f1f1f;
  font-weight: 400;
  padding: 5px;
  font-size: 14px;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  color: #B1B1B1;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active {
  background: #845DB6;
  color: #fff;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:hover {
  background: #FFEFFF;
}
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__days .react-calendar__tile:hover.react-calendar__tile--active {
  background: #B56CCF;
}

/* -----

 MEDIAQUERIES 

----- */
/* ------------------------------------------------------------
     AUTH
------------------------------------------------------------ */
.auto-row__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/* active code */
.active-choose {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.active-choose button {
  margin-right: 30px;
}
.active-choose button:last-child {
  margin-right: 0;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .auto-row__right {
    display: none;
  }
  .active-choose {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
/* ------------------------------------------------------------
     DONATE
------------------------------------------------------------ */
.box-donate-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 32px;
}

.box-donate {
  margin: 0 16px;
}
.box-donate button,
.box-donate a {
  background: #fff;
  border: 1px solid #E4A9BB;
  border-radius: 16px;
  padding: 32px;
  color: #1f1f1f;
  width: 277px;
  height: 233px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  text-decoration: none;
}
.box-donate button:hover, .box-donate button.active,
.box-donate a:hover,
.box-donate a.active {
  border-color: #1F7164;
  background: #CDF8E5;
}
.box-donate button:hover p, .box-donate button.active p,
.box-donate a:hover p,
.box-donate a.active p {
  color: #1F7164;
}

.box-donate__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.box-donate__icon .icon {
  margin: 0 8px;
}

/* donate banner */
.donate-banner {
  background: #CDF8E5;
  border-radius: 10px;
  padding: 24px;
  border: 1px solid #91D6C4;
}
.donate-banner .row {
  margin: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-card table p {
  margin-bottom: 0;
}
.dashboard-card .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dashboard-card__col {
  border-right: 1px solid #CDCDCD;
}
.dashboard-card__col:last-child, .dashboard-card__col:last-of-type {
  border-right: 0;
}
.dashboard-card__col:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 992px) {
  .box-donate {
    margin-bottom: 32px;
  }
}
@media (max-width: 768px) {
  .box-donate {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }
  .box-donate button,
  .box-donate a {
    width: 100%;
    height: 120px;
    padding: 10px 32px;
  }
  .box-donate-grid {
    margin-bottom: 0;
  }
}
/* ------------------------------------------------------------
     PROFILE
------------------------------------------------------------ */
.user-profile-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.user-profile-data figure {
  margin: 0 16px 0 0;
  width: 120px;
  height: 120px;
}
.user-profile-data figure img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user-data-links {
  width: 100%;
  border-bottom: 1px solid #BDBDBD;
  margin-bottom: 16px;
}
.user-data-links:last-child {
  border-bottom: 0;
}
.user-data-links a,
.user-data-links button {
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  width: 100%;
  margin-bottom: 8px;
  color: #1f1f1f;
  text-align: left;
}
.user-data-links a:hover,
.user-data-links button:hover {
  background: #F6F6F6;
}
.user-data-links a.active,
.user-data-links button.active {
  background: #1F7164;
  color: #fff;
  font-family: "CircularStd-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.data-row {
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.data-row:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.data-link-select {
  display: none;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .user-profile-data figure {
    width: 60px;
    height: 60px;
  }
  .data-link-top {
    display: none;
  }
  .data-link-select {
    display: block;
  }
}
/* ------------------------------------------------------------
     DASHBOARD
------------------------------------------------------------ */
.dashboard-aside__inner {
  position: sticky;
  top: 0;
}

.dashboard-info__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 32px;
}

/* top title */
.top-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* user tab col */
.user-info-tab__subtitle-right {
  text-align: right;
}

.user-info-tab__col {
  margin-bottom: 24px;
}

/* view body */
.view-body__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* dashboard admin */
.dashboard-index {
  background: #F6F6F6;
}

.dashboard-index__container {
  padding-bottom: 36px;
  padding-top: 36px;
}

.dashboard-card {
  padding: 32px 22px;
  border-radius: 8px;
}
.dashboard-card.dashboard--1 {
  background: #6BBBAE;
}
.dashboard-card.dashboard--2 {
  background: #fff;
  border: 1px solid #8A8A8A;
}
.dashboard-card.dashboard--3 {
  background: #CDF8E5;
  border: 1px solid #1F7164;
}
.dashboard-card.dashboard--4 {
  background: #fff;
  border: 1px solid #1F7164;
}
.dashboard-card.dashboard--v100 {
  height: 100%;
}
.dashboard-card.dashboard--5 {
  background: #F6F6F6;
  border: 1px solid #6BBBAE;
}

.dashboard-card__inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.dashboard-card__inner-content {
  width: 100%;
}

.dashboard-card__buttons {
  width: 100%;
  padding-top: 48px;
}
.dashboard-card__buttons ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dashboard-card__buttons ul li {
  margin-bottom: 8px;
  width: 100%;
}
.dashboard-card__buttons ul li:last-child {
  margin-bottom: 0;
}
.dashboard-card__buttons ul li .btn {
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

/* logo */
.logo-init {
  width: 146px;
  margin: 0;
}
.logo-init img {
  width: 100%;
}

/* number */
.data-number-currency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.data-number-currency__init {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 8px;
}
.data-number-currency__init small {
  padding-right: 8px;
}

.donations-list__item {
  width: 100%;
}
.donations-list__item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.donations-list__item ul li {
  width: 100%;
  margin-bottom: 12px;
}

/* talleres */
.talleres-list {
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.talleres-list ul {
  list-style: none;
  padding: 0 20px;
  margin: 0;
}
.talleres-list ul li {
  width: 100%;
  margin-bottom: 12px;
}

/* colectiva */
.colectiva-list-data {
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.colectiva-list-data ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.colectiva-list-data ul li {
  width: 100%;
  margin-bottom: 12px;
}

.dashboard-index__middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* title section */
.title-section.title--between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 1200px) {
  .dashboard-index__desktop {
    display: none;
  }
  .dashboard-index__middle {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 992px) {
  .user-info-tab__subtitle-right {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .title-section {
    display: none;
  }
  .dashboard-aside {
    margin-bottom: 24px;
  }
  .dashboard-info__bottom {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .top-title {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .view-head-row {
    margin-bottom: 32px;
  }
  .dashboard-card .btn {
    width: 100%;
  }
  .data-number-currency .text-24 {
    font-size: 16px;
    line-height: 18px;
  }
}
/* ------------------------------------------------------------
     USER DETAIL
------------------------------------------------------------ */
.view-head {
  width: 100%;
}

.view-detail__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.view-detail__tags > div {
  margin-right: 8px;
  margin-bottom: 8px;
}

.view-detail__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.view-detail__action.no-flex {
  display: block;
}
.view-detail__action .btn {
  margin-bottom: 16px;
  max-width: 270px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.view-detail__action-col {
  text-align: right;
}
.view-detail__action-col .btn {
  margin-bottom: 16px;
}

.view-body__aside .btn {
  position: sticky;
  top: 24px;
}

.view-body__info {
  padding-right: 30px;
}

.table-user-data {
  width: 100%;
}
.table-user-data td:last-child {
  text-align: right;
  color: #1f1f1f;
  font-weight: 500;
}

.data-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #8A8A8A;
  font-weight: 500;
}
.data-info i {
  margin-right: 16px;
}

/* image user */
.image-user {
  width: 100%;
  max-width: 348px;
  border-radius: 18px;
  border: 1px solid #91D6C4;
}
.image-user figure {
  width: 100%;
  height: 222px;
  padding: 19px;
}
.image-user figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  overflow: hidden;
  border-radius: 14px;
}

.image-user__data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 19px;
  border-top: 1px solid #91D6C4;
}
.image-user__data article {
  width: 90%;
}
.image-user__data article p {
  margin-bottom: 0;
  padding-left: 8px;
}

/* -----

 MEDIAQUERIES 

----- */
@media (max-width: 768px) {
  .view-head {
    background: transparent;
    border: 0;
    padding: 0;
  }
  .view-detail__action button {
    width: 100%;
  }
  .view-detail h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .data-info p {
    margin: 0;
  }
  .view-body__info {
    padding: 0;
  }
}
/* ------------------------------------------------------------
    LARGE
------------------------------------------------------------ */
/* ------------------------------------------------------------
    MEDIUM
------------------------------------------------------------ */
@media (max-width: 992px) {
  .hide-tablet {
    display: none !important;
  }
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 45px;
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: 35px;
  }
  h3,
  .h3 {
    font-size: 21px;
    line-height: 35px;
  }
  .module {
    padding: 40px 0;
  }
  .module-bottom {
    padding-bottom: 40px;
  }
  .module-top {
    padding-top: 40px;
  }
  .module40 {
    padding: 30px 0;
  }
  .module-bottom40 {
    padding-bottom: 30px;
  }
  .module-top40 {
    padding-top: 30px;
  }
  .text-24,
  .text-24 *,
  .text-20,
  .text-20 * {
    font-size: 18px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 * {
    font-size: 24px;
    line-height: 35px;
  }
  .text-64,
  .text-64 * {
    font-size: 40px;
    line-height: 45px;
  }
  .text-52,
  .text-52 * {
    font-size: 32px;
    line-height: 40px;
  }
}
/* ------------------------------------------------------------
    SMALL
------------------------------------------------------------ */
@media (max-width: 680px) {
  .body-row {
    margin: 0;
  }
  .module {
    padding: 30px 0;
  }
  .module-bottom {
    padding-bottom: 30px;
  }
  .module-top {
    padding-top: 30px;
  }
  .module40 {
    padding: 20px 0;
  }
  .module-bottom40 {
    padding-bottom: 20px;
  }
  .module-top40 {
    padding-top: 20px;
  }
  .text-20,
  .text-20 * {
    font-size: 16px;
    line-height: 25px;
  }
  .text-32,
  .text-32 *,
  .text-40,
  .text-40 * {
    font-size: 20px;
    line-height: 30px;
  }
  .text-64,
  .text-64 * {
    font-size: 32px;
    line-height: 40px;
  }
  .hide-mobile {
    display: none;
  }
}